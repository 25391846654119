<template>
    <div id="app" class="h-100" :class="[skinClasses]">
        <component :is="layout">
            <router-view />
        </component>
        <scroll-to-top v-if="enableScrollToTop" />
        <b-modal
            v-model="isModalVisible"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
        >
            <WishCard :wishes="wishes" />
        </b-modal>
    </div>
</template>

<script>
// This will be populated in `beforeCreate` hook
import ScrollToTop from "@core/components/scroll-to-top/ScrollToTop.vue";
import { $themeColors, $themeBreakpoints, $themeConfig } from "@themeConfig";
import { provideToast } from "vue-toastification/composition";
import { watch } from "@vue/composition-api";
import useAppConfig from "@core/app-config/useAppConfig";
import WishCard from "./views/WishCard.vue";
import { useWindowSize, useCssVar } from "@vueuse/core";
import axios from "@axios";

import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import Vue from "vue";
import { bus } from "@/main.js";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);
import VueCryptojs from "vue-cryptojs";
Vue.use(VueCryptojs);

const LayoutVertical = () => import("@/layouts/vertical/LayoutVertical.vue");
const LayoutHorizontal = () =>
    import("@/layouts/horizontal/LayoutHorizontal.vue");
const LayoutFull = () => import("@/layouts/full/LayoutFull.vue");
export default {
    components: {
        // Layouts
        LayoutHorizontal,
        LayoutVertical,
        LayoutFull,
        ScrollToTop,
        WishCard,
    },
    
    data() {
        return {
            wishes: [], // Initialize it as an empty array or your default data
            isModalVisible: false,
            isCelebrate: false,
        };
    },
    
    // ! We can move this computed: layout & contentLayoutType once we get to use Vue 3
    // Currently, router.currentRoute is not reactive and doesn't trigger any change
    computed: {
        layout() {
            if (this.$route.meta.layout === "full") return "layout-full";
            return `layout-${this.contentLayoutType}`;
        },
        contentLayoutType() {
            return this.$store.state.appConfig.layout.type;
        },
    },
    beforeCreate() {
        // Set colors in theme
        const colors = [
            "primary",
            "secondary",
            "success",
            "info",
            "warning",
            "danger",
            "light",
            "dark",
        ];

        // eslint-disable-next-line no-plusplus
        for (let i = 0, len = colors.length; i < len; i++) {
            $themeColors[colors[i]] = useCssVar(
                `--${colors[i]}`,
                document.documentElement
            ).value.trim();
        }

        // Set Theme Breakpoints
        const breakpoints = ["xs", "sm", "md", "lg", "xl"];

        // eslint-disable-next-line no-plus
        for (let i = 0, len = breakpoints.length; i < len; i++) {
            $themeBreakpoints[breakpoints[i]] = Number(
                useCssVar(
                    `--breakpoint-${breakpoints[i]}`,
                    document.documentElement
                ).value.slice(0, -2)
            );
        }

        // Set RTL
        const { isRTL } = $themeConfig.layout;
        document.documentElement.setAttribute("dir", isRTL ? "rtl" : "ltr");
        localStorage.removeItem('vuexy-skin');
    },
    created() {
        var arr = [
            "misc-not-authorized",
            "auth-login",
            "register",
            "terms",
            "privacy-policy",
            "authorize-callback",
            "register-callback",
            "authorize-callback-mobile",
            "register-callback-mobile",
            "authorize",
            "activate",
            "registration-completed",
            "password-generate",
            "register",
            "find-host",
            "forgot-password",
            "password-reset",
            "Zircly-Zoom-Integration-App-Documentation",
            "Delete My Zircly Account",
            "onboarding",
            "public-post",
        ];
        if (
            !arr.includes(this.$route.name) &&
            localStorage.getItem("EncryptedAbilities")
        ) {
            var DecryptedAbilities = JSON.parse(
                this.$CryptoJS.AES.decrypt(
                    localStorage.getItem("EncryptedAbilities"),
                    "ZirclyRocks"
                ).toString(this.$CryptoJS.enc.Utf8)
            );
            if (DecryptedAbilities) {
                this.$ability.update([
                    {
                        subject: "all",
                        actions: DecryptedAbilities,
                    },
                ]);
            } else {
                axios
                    .get("abilities")
                    .then((response) => {
                        if (response.status == "200") {
                            this.$ability.update([
                                {
                                    subject: "all",
                                    actions: response.data.data,
                                },
                            ]);
                            const encryptedText = this.CryptoJS.AES.encrypt(
                                JSON.stringify(response.data.data),
                                "ZirclyRocks"
                            ).toString();
                            localStorage.setItem(
                                "EncryptedAbilities",
                                encryptedText
                            );
                        }
                        if (response.status == "401") {
                            this.$router.push({ name: "auth-login" });
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        }

        bus.$on("celebrate-check", (celebrate) => {
                const urlParams = new URLSearchParams(window.location.search);
                const filter = urlParams.get('post_hash_id');
                if (celebrate && !filter ) {
                    this.fetchWishes();
                } else if (this.wishes && this.wishes.length > 0 && !filter ) {
                    this.isModalVisible = celebrate;
                    // this.playMusic()
                }
            });
    },
    mounted() {
        const urlParams = new URLSearchParams(window.location.search);
        const filter = urlParams.get('post_hash_id');
        console.log(filter);
        if ((this.$cookies.get("userData") || useJwt.getToken()) && !filter ) {
            this.fetchWishes();
        }
    },
    methods: {
        checkUser() {
            // if(!this.$cookies.get('TenantHost') && process.env.VUE_APP_API_URL == axios.defaults.baseURL){
            //   this.$router.push('/find-host').then(() => {
            //       this.$toast(
            //         {
            //           component: ToastificationContent,
            //           position: "top-right",
            //           props: {
            //             title: `Welcome Back`,
            //             icon: "CoffeeIcon",
            //             variant: "success",
            //             text: `Unable to find your organization, Plese enter your Official Email`,
            //           },
            //         }
            //       );
            //     })
            //     .catch((error) => {
            //       this.$refs.log.setErrors(error.response.data.error);
            //       // this.$toast({
            //       //   component: ToastificationContent,
            //       //   props: {
            //       //     title: 'Organization Not Found',
            //       //     icon: 'BellIcon',
            //       //     text: error,
            //       //     variant: 'danger',
            //       //   },
            //       // })
            //     });
            //   return false;
            // }else{
            //   axios.defaults.baseURL =  this.$cookies.get('TenantHost');
            // }
            if (!this.$cookies.get("userData") || !useJwt.getToken()) {
                this.$router
                    .push("/login")
                    .then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            position: "top-right",
                            props: {
                                title: `Welcome Back`,
                                icon: "CoffeeIcon",
                                variant: "success",
                                text: `Your Session has been expired , You have to login again`,
                            },
                        });
                    })
                    .catch((error) => {
                        this.$refs.log.setErrors(error.response.data.error);
                    });
                return false;
            } else {
                if (
                    process.env.VUE_APP_TAWK_API &&
                    this.$cookies.get("userData") &&
                    this.$cookies.get("userData").role == "Zircly Admin"
                ) {
                    var Tawk_API = Tawk_API || {},
                        Tawk_LoadStart = new Date();
                    (function () {
                        var s1 = document.createElement("script"),
                            s0 = document.getElementsByTagName("script")[0];
                        s1.async = true;
                        s1.src =
                            "https://embed.tawk.to/63282a8737898912e969fdab/1gdaec8qv";
                        s1.charset = "UTF-8";
                        s1.setAttribute("crossorigin", "*");
                        s0.parentNode.insertBefore(s1, s0);
                    })();
                }
                // this.$http.get("/testing/connection")
                //     .then((response) => {
                //       console.log(response);
                //     });

                return true;
            }
        },
        fetchWishes() {
            axios
                .get("wish/getWishes", {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem(
                            "token"
                        )}`, // Add token if necessary
                    },
                })
                .then((response) => {
                    if (response.data.success) {
                        this.wishes = response.data.data;
                        console.log(this.wishes);
                        
                        if (this.wishes.length > 0) {
                            this.isModalVisible = true;
                        }
                    }
                })
                .catch((error) => {
                    console.error("Error fetching data:", error);
                });
        },
    },
    watch: {
        $route: {
            handler() {
                // if(window.location.href == process.env.VUE_APP_GLOBAL_DOMAIN || window.location.href == process.env.VUE_APP_GLOBAL_DOMAIN+'/login'){
                //   window.location = process.env.VUE_APP_GLOBAL_DOMAIN+'/find-host';
                // }
                //login token expiry check
                var checkUser = false;
                var arr = [
                    "misc-not-authorized",
                    "auth-login",
                    "register",
                    "terms",
                    "privacy-policy",
                    "authorize-callback",
                    "register-callback",
                    "authorize-callback-mobile",
                    "register-callback-mobile",
                    "authorize",
                    "activate",
                    "registration-completed",
                    "password-generate",
                    "register",
                    "find-host",
                    "forgot-password",
                    "password-reset",
                    "Zircly-Zoom-Integration-App-Documentation",
                    "Delete My Zircly Account",
                    "onboarding",
                    "public-post",
                    null,
                ];
                if (!arr.includes(this.$route.name)) {
                    checkUser = this.checkUser();
                }
                // if (checkUser) {
                //   axios
                //     .get("abilities")
                //     .then((response) => {
                //       if (response.status == "200") {
                //         this.$ability.update([
                //           {
                //             subject: "all",
                //             actions: response.data.data,
                //           },
                //         ]);
                //       }
                //       if (response.status == "401") {
                //         this.$router.push({ name: "auth-login" });
                //       }
                //     })
                //     .catch((error) => {
                //       console.log(error);
                //     });
                // }
            },
            immediate: true,
        },
    },
    // created(){
    //   if(window.location.href == process.env.VUE_APP_GLOBAL_DOMAIN || window.location.href == process.env.VUE_APP_GLOBAL_DOMAIN+'/login'){
    //     window.location = process.env.VUE_APP_GLOBAL_DOMAIN+'/find-host';
    //   }
    // },
    setup() {
        const { skin, skinClasses } = useAppConfig();
        const { enableScrollToTop } = $themeConfig.layout;

        localStorage.removeItem('vuexy-skin');
        skin.value = 'light';
    // Force remove dark-layout class from body if present
         document.body.classList.remove("dark-layout");
        // If skin is dark when initialized => Add class to body
        // if (skin.value === "dark") document.body.classList.add("dark-layout");

        // Provide toast for Composition API usage
        // This for those apps/components which uses composition API
        // Demos will still use Options API for ease
        provideToast({
            hideProgressBar: true,
            closeOnClick: false,
            closeButton: false,
            icon: false,
            timeout: 3000,
            transition: "Vue-Toastification__fade",
        });

        // Set Window Width in store
        store.commit("app/UPDATE_WINDOW_WIDTH", window.innerWidth);
        const { width: windowWidth } = useWindowSize();
        watch(windowWidth, (val) => {
            store.commit("app/UPDATE_WINDOW_WIDTH", val);
        });

        return {
            skinClasses,
            enableScrollToTop,
        };
    },
};
</script>
<!--Start of Tawk.to Script-->

<!--End of Tawk.to Script-->
<style>
.table thead tr th {
    text-transform: capitalize !important;
}
</style>
<style lang="scss">
.brand-logo img {
    width: 45px;
}
</style>
