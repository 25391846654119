import Vue from "vue";
import {
    ToastPlugin,
    ModalPlugin,
    BootstrapVue,
    BootstrapVueIcons
} from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";

import router from "./router";
import store from "./store";
import App from "./App.vue";

// Vuesax Component Framework
import Vuesax from "vuesax";
import "material-icons/iconfont/material-icons.css"; //Material Icons
import "vuesax/dist/vuesax.css"; // Vuesax
Vue.use(Vuesax);

import Ripple from 'vue-ripple-directive'
// Ripple.color = '#A7FFA0';
Ripple.color = 'rgba(167,255,160,0.15)';
Vue.directive('ripple', Ripple);

export const bus = new Vue();

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";
import "@/libs/sweet-alerts";
// import "@/libs/vue-select";

import vSelect from "vue-select";

vSelect.props.components.default = () => ({
    Deselect: {
        render: createElement =>
            createElement("feather-icon", {
                props: {
                    icon: "XIcon",
                    svgClasses: "w-4 h-4 mt-1"
                }
            })
    },
    OpenIndicator: {
        render: createElement =>
            createElement("feather-icon", {
                props: {
                    icon: "ChevronDownIcon",
                    svgClasses: "w-5 h-5"
                }
            })
    }
});

Vue.component("v-select", vSelect);

// Acl Import
import "@/libs/acl";

/* eslint-disable global-require */
import "./registerServiceWorker";
import moment from "moment";
// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

// Composition API
Vue.use(VueCompositionAPI);

import VueClipboard from "vue-clipboard2";

Vue.use(VueClipboard);

// import core styles
require("@core/scss/core.scss");

// Feather font icon - For form-wizard
require("@core/assets/fonts/feather/iconfont.css"); // For form-wizard

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

Vue.filter("str_limit", function(value, size) {
    if (!value) return "";
    value = value.toString();

    if (value.length <= size) {
        return value;
    }
    return value.substr(0, size) + "...";
});
Vue.filter("formatMonthDay", function(value) {
    if (value) {
        return moment(String(value)).format("MMM D");
    }
});
Vue.filter("formatDateTime", function(value) {
    if (value) {
        return moment(String(value)).format("MMM D, YYYY, h:mm a");
    }
});
Vue.filter("getDate", function(value) {
    if (value) {
        return moment(String(value)).format("DD");
    }
});
Vue.filter("getMonth", function(value) {
    if (value) {
        return moment(String(value)).format("MMM");
    }
});
Vue.filter("formatDate", function(value,format = 'MMM D, YYYY') {
    if (value) {
        return moment(String(value)).format(format);
    }
});
Vue.filter("formatDateRange", function(value,format = 'MMM D, YYYY') {
    if (value) {
        var start  = value.split(' to ')[0];
        var end  = value.split(' to ')[1];
        
        return moment(String(start)).format(format)+" to "+moment(String(end)).format(format);
    }
});
Vue.filter("formatDateWithoutYear", function(value) {
    if (value) {
        return moment(String(value)).format("D, MMM");
    }
});
Vue.filter("formatLabel", function(value) {
    if (value) {
        let wordsArray = ["ctc"];
        let string = String(value)
            .replace(/(?:^|\s)\S/g, function(a) {
                return a.toUpperCase();
            })
            .replaceAll("label", "")
            .replaceAll("_", " ");

        return string;
    }
});

Vue.filter("formatDates", function(value) {
    if (value) {
        var myStringArray = value.split(",");
        var outputStringArray = [];
        for (var i = 0; i < myStringArray.length; i++) {
            outputStringArray.push(
                moment(String(myStringArray[i])).format("MMM D, YYYY")
            );
        }
        var outputString = outputStringArray.join(", ");
        return outputString;
    }
});
Vue.filter("formatDatesArray", function(value) {
    if (value) {
        var myStringArray = value;
        var outputStringArray = [];
        for (var i = 0; i < myStringArray.length; i++) {
            outputStringArray.push(
                moment(String(myStringArray[i])).format("MMM D, YYYY")
            );
        }
        var outputString = outputStringArray.join(", ");
        return outputString;
    }
});

Vue.filter("formatArrayToSting", function(value, key) {
    if (value) {
        var myStringArray = value;
        var outputStringArray = [];
        for (var i = 0; i < myStringArray.length; i++) {
            outputStringArray.push(String(myStringArray[i][key]));
        }
        var outputString = outputStringArray.join(", ");
        return outputString;
    }
});

Vue.filter("formatTime", function(value,format='h:mm a') {
    if (value) {
        return moment(String("2022-06-16 " + value)).format(format);
    }
});
Vue.filter("formatJustTime", function(value,format='h:mm a') {
    if (value) {
        return moment(String(value)).format(format);
    }
});
Vue.filter("formatTimeFormDate", function(value) {
    if (value) {
        return moment(String(value)).format("h:mm A");
    }
});

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");


import axios from "@axios";
import Echo from "laravel-echo"

window.Pusher = require('pusher-js');
window.Echo = new Echo({
    broadcaster: 'pusher',
    key: 'testing-key',
    wsHost: process.env.VUE_APP_WEBSOCKET_URL,
    wssHost: process.env.VUE_APP_WEBSOCKET_URL,
    wsPort: 6001,
    wssPort: 6001,
    disableStats: true,
    forceTLS: false,
    enabledTransports: ['ws', 'wss'],
    cluster: 'eu',
  
    //authEndpoint: process.env.VUE_APP_API_URL+'/broadcasting/auth',
    authorizer: (channel, options) => {
        return {
            authorize: (socketId, callback) => {
                axios.post('/broadcasting/auth', {
                    socket_id: socketId,
                    channel_name: channel.name
                })
                .then(response => {
                    callback(null, response.data);
                })
                .catch(error => {
                    callback(error);
                });
            }
        };
    },
});
